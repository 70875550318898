import { CustomOptions } from "../../components/styled-UI/CustomSelect";
import { RequestStatus } from "../../interfaces/mainInterfaces";
import { ItemPrice } from "../../interfaces/productInterface";

export interface MessagesState {
  //NOTIFICATIONS
  notifications: NotificationsListRemapped;
  notificationsPreferences: NotificationPreference[] | null;
  notificationsPreferencesStatus: RequestStatus;
  updateNotificationsPreferencesStatus: RequestStatus;
  notificationsStatus: RequestStatus;
  markedNotificationsStatus: RequestStatus;

  //GREEN IS BACK
  backordersAvailability: BackordersAvailability | null;
  backordersAvailabilityStatus: SectionStatus;
  backorderPricesStatus: RequestStatus;
  backordersState: BackordersState;
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// NOTIFICATIONS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface NotificationsListRemapped {
  notifications: Notifications[];
  totalElements: number;
  totalPages: number;
}

export interface Notifications {
  notificationId: number;
  serviceId: number;
  creationDate?: string;
  read?: boolean;
  additionalData: NotificationAdditionalData;
}

export interface NotificationAdditionalData {
  invoice?: string; // data?.additionalData?.invoice
  invoiceIdentifier?: string; // data?.additionalData?.InvoiceIdentifier
  orderId?: string; // data?.additionalData?.orderId
  warrantyId?: string; // data?.additionalData?.warrantyId
  serviceRequestId?: string; // data?.additionalData?.serviceRequestId
  documentId?: string; // data?.additionalData?.documentId
  sku?: string; // data?.additionalData?.sku
  seoUrl?: string; // data?.additionalData?.seoUrl
  orderToBeApprovedUser?: string; // data?.additionalData?.username
  status?: string; // data?.additionalData?.status
  assetName?: string;
  assetDate?: string;
}

///////////////////////////////////// REDUCERS ///////////////////////////////////

export interface MarkNotificationsAsReadUnread {
  notificationId: number;
  isRead: boolean;
}

///////////////////////////////////// PAYLOADS ///////////////////////////////////

export interface NotificationsFiltersPayload {
  serviceId?: number;
  dateFrom?: string;
  dateTo?: string;
  pageNumber?: number;
}

export interface GetAllNotificationsListPayload extends NotificationsFiltersPayload {
  typeId: number;
  pageSize: number;
}

export interface MarkAsReadOrUnreadNotificationsPayload {
  read: boolean;
  notifications: number[];
  callback?: (success?: boolean) => void;
}

export interface DeleteNotificationsPayload {
  notifications: number[];
  callback?: (success?: boolean) => void;
}

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// NOTIFICATIONS PREFERENCES ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

type NotificationsActionsCategories = "other" | "email" | "sms" | "alert";

export const mapNotificationTypeIdToType: { [key: number]: NotificationsActionsCategories } = {
  0: "other",
  1: "email",
  2: "sms",
  3: "alert",
};

export interface NotificationPreferenceActions extends NotificationPreferencesUpdatePayload {
  type: NotificationsActionsCategories;
  enabled: boolean;
}

export interface NotificationPreference {
  serviceId: number;
  type: string;
  description: string;
  actions: NotificationPreferenceActions[];
  privileges: string[];
  hide?: boolean;
}

export interface NotificationPreferencesUpdatePayload {
  serviceId: number;
  active: boolean;
  typeId: number;
}

///////////////////////////////////// PAYLOADS ///////////////////////////////////

export interface UpdateNotificationsPreferencesPayload {
  updatedNotifications: NotificationPreferenceActions[];
  callback?: (success?: boolean) => void;
}

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// GREEN IS BACK /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface SectionStatus {
  status: RequestStatus;
  section?: string;
}

export interface BackordersState {
  availableProducts: { currentPage: number; filters?: CustomOptions };
  backorderProducts: { currentPage: number; filters?: CustomOptions };
  outOfStockProducts: { currentPage: number; filters?: CustomOptions };
}

export interface BackordersAvailability {
  availableProducts: BackordersAvailabilitySection;
  backorderProducts: BackordersAvailabilitySection;
  outOfStockProducts: BackordersAvailabilitySection;
}

export interface BackordersAvailabilitySection {
  content: AvailableProduct[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface AvailableProduct {
  id: string;
  catentryId: string;
  partnumber: string;
  upc: string;
  fullImage?: string;
  fullImageRaw?: string;
  relationshipVariantSeo?: string;
  manufacturer?: string;
  price?: ItemPrice;
}

export interface InventoryTrackingPayload {
  orderBy?: OrderBy;
  pageSize?: number;
  pageNumber?: number;
  inventoryStatus?: InventoryStatus[];
  doorId?: string;
}

export type OrderBy = "PARTNUMBER" | "BRAND";
export type InventoryStatus = "AVAILABLE" | "BACKORDER" | "NOT_AVAILABLE";

export type AvailabilitySections = "availableProducts" | "backorderProducts" | "outOfStockProducts";

export type InventoryToAvailability<T> = {
  [P in keyof T]: InventoryStatus;
};

export interface DeleteInventoryTrackingPayload {
  inventoryTrackingId: string;
  section?: AvailabilitySections;
  uniqueID?: string;
  isCart?: boolean;
}

export interface AddInventoryTrackingPayload {
  id: string;
  isCart?: boolean;
}
