import {
  InventoryStatus,
  NotificationAdditionalData,
  NotificationPreference,
  NotificationPreferenceActions,
  NotificationPreferencesUpdatePayload,
  Notifications,
  NotificationsListRemapped,
  mapNotificationTypeIdToType,
} from "../store/messages/messagesInterfaces";

import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
} from "date-fns";
import { getDateFromGenericString } from "./dateUtils";
import { replaceUnderscoreProductCode } from "./rxUtils";

export const PRODUCTS_SECTIONS = [
  "availableProducts",
  "backorderProducts",
  "outOfStockProducts",
] as const;

export const sectionToStatusMap: { [x: string]: InventoryStatus } = {
  availableProducts: "AVAILABLE",
  backorderProducts: "BACKORDER",
  outOfStockProducts: "NOT_AVAILABLE",
};
export const statusToSectionMap: { [x: string]: string } = {
  AVAILABLE: "availableProducts",
  BACKORDER: "backorderProducts",
  NOT_AVAILABLE: "outOfStockProducts",
};

export const GREEN_IS_BACK_SORT_OPTIONS = ["PARTNUMBER", "BRAND"];

//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////// NOTIFICATIONS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export interface NotificationsGroupConfig {
  [key: string]: {
    labelKey: string;
    notifications: Notifications[];
  };
}

export type NotificationsGroupedKeys = {
  [key: number]: string;
};

export const NOTIFICATIONS_GROUPED_DAYS_KEYS: NotificationsGroupedKeys = {
  1: "oneDayAgo",
  2: "twoDaysAgo",
  3: "threeDaysAgo",
  4: "fourDaysAgo",
  5: "fiveDaysAgo",
  6: "sixDaysAgo",
  7: "sevenDaysAgo",
};

export const NOTIFICATIONS_GROUPED_WEEKS_KEYS: NotificationsGroupedKeys = {
  1: "twoWeeksAgo",
  2: "twoWeeksAgo",
  3: "threeWeeksAgo",
  4: "fourWeeksAgo",
};

export const NOTIFICATIONS_GROUPED_MONTHS_KEYS: NotificationsGroupedKeys = {
  1: "oneMonthAgo",
  2: "twoMonthsAgo",
  3: "threeMonthsAgo",
  4: "fourMonthsAgo",
  5: "fiveMonthsAgo",
  6: "sixMonthsAgo",
  7: "sevenMonthsAgo",
  8: "eightMonthsAgo",
  9: "nineMonthsAgo",
  10: "tenMonthsAgo",
  11: "elevenMonthsAgo",
  12: "twelveMonthsAgo",
  13: "oneYearAgo",
};

/**
 * get the key for the NOTIFICATIONS_GROUP_CONFIG  object (e.g today, oneDayAgo etc.)
 */

export const getNotificationsGroupKey = (
  currentDate: Date | undefined,
  earlierDate: Date | undefined
): string | undefined => {
  if (currentDate === undefined || earlierDate === undefined) return undefined;

  const days = differenceInCalendarDays(currentDate, earlierDate);
  const months = differenceInCalendarMonths(currentDate, earlierDate);
  const weeks = differenceInCalendarWeeks(currentDate, earlierDate);
  const years = differenceInCalendarYears(currentDate, earlierDate);

  if (days == 0) return "today";
  if (days > 0 && days <= 7) return NOTIFICATIONS_GROUPED_DAYS_KEYS[days];
  if (days > 7 && weeks <= 4) return NOTIFICATIONS_GROUPED_WEEKS_KEYS[weeks];
  if (weeks > 4 && months <= 12) return NOTIFICATIONS_GROUPED_MONTHS_KEYS[months];
  if (months > 12 && years == 1) return NOTIFICATIONS_GROUPED_MONTHS_KEYS[months];
  return undefined;
};

export const NOTIFICATIONS_GROUP_CONFIG: NotificationsGroupConfig = {
  today: {
    labelKey: "today",
    notifications: [],
  },
  oneDayAgo: {
    labelKey: "NOTIFICATION_GROUP_ONE_DAY_AGO",
    notifications: [],
  },
  twoDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_TWO_DAYS_AGO",
    notifications: [],
  },
  threeDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_THREE_DAYS_AGO",
    notifications: [],
  },
  fourDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_FOUR_DAYS_AGO",
    notifications: [],
  },
  fiveDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_FIVE_DAYS_AGO",
    notifications: [],
  },
  sixDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_SIX_DAYS_AGO",
    notifications: [],
  },
  sevenDaysAgo: {
    labelKey: "NOTIFICATION_GROUP_SEVEN_DAYS_AGO",
    notifications: [],
  },
  twoWeeksAgo: {
    labelKey: "NOTIFICATION_GROUP_TWO_WEEKS_AGO",
    notifications: [],
  },
  threeWeeksAgo: {
    labelKey: "NOTIFICATION_GROUP_THREE_WEEKS_AGO",
    notifications: [],
  },
  fourWeeksAgo: {
    labelKey: "NOTIFICATION_GROUP_FOUR_WEEKS_AGO",
    notifications: [],
  },
  oneMonthAgo: {
    labelKey: "NOTIFICATION_GROUP_ONE_MONTH_AGO",
    notifications: [],
  },
  twoMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_TWO_MONTHS_AGO",
    notifications: [],
  },
  threeMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_THREE_MONTHS_AGO",
    notifications: [],
  },
  fourMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_FOUR_MONTHS_AGO",
    notifications: [],
  },
  fiveMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_FIVE_MONTHS_AGO",
    notifications: [],
  },
  sixMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_SIX_MONTHS_AGO",
    notifications: [],
  },
  sevenMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_SEVEN_MONTHS_AGO",
    notifications: [],
  },
  eightMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_EIGHT_MONTHS_AGO",
    notifications: [],
  },
  nineMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_NINE_MONTHS_AGO",
    notifications: [],
  },
  tenMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_TEN_MONTHS_AGO",
    notifications: [],
  },
  elevenMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_ELEVEN_MONTHS_AGO",
    notifications: [],
  },
  twelveMonthsAgo: {
    labelKey: "NOTIFICATION_GROUP_TWELVE_MONTHS_AGO",
    notifications: [],
  },
  oneYearAgo: {
    labelKey: "NOTIFICATION_GROUP_ONE_YEAR_AGO",
    notifications: [],
  },
};

export const mapNotificationsList = (data: any): NotificationsListRemapped => {
  return {
    notifications: data?.notifications?.map((_: any) => mapNotification(_)) ?? [],
    totalElements: data?.totalElements ? Number(data?.totalElements) : 0,
    totalPages: data?.totalPages ? Number(data?.totalPages) : 0,
  };
};

export const mapNotification = (data: any): Notifications => {
  const additionalData: NotificationAdditionalData = {
    sku: data?.additionalData?.sku
      ? replaceUnderscoreProductCode(data?.additionalData?.sku)?.replace("_", " ")
      : undefined,
    invoiceIdentifier: data?.additionalData?.invoiceIdentifier,
    orderId: data?.additionalData?.orderId,
    warrantyId: data?.additionalData?.warrantyId,
    serviceRequestId: data?.additionalData?.serviceRequestId,
    documentId: data?.additionalData?.documentId,
    seoUrl: data?.additionalData?.seoUrl,
    orderToBeApprovedUser: data?.additionalData?.username,
    status: data?.additionalData?.status,
    assetDate: data?.additionalData?.date,
    assetName: data?.additionalData?.name,
  };

  return {
    notificationId: data?.id,
    serviceId: data?.serviceId,
    creationDate: data?.tsInsert,
    read: data?.read,
    additionalData,
  };
};

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////// NOTIFICATIONS PREFERENCES ///////////////////////////
//////////////////////////////////////////////////////////////////////////////////

const NOTIFICATION_PREFERENCES_CONFIG: { [key: number]: NotificationPreference } = {
  5: {
    serviceId: 5,
    type: "NOTIFICATION_INVOICE_TITLE",
    description: "NOTIFICATION_INVOICE_DESCRIPTION",
    privileges: ["INVOICE_SECTION"],
    actions: [],
  },
  27: {
    serviceId: 27,
    type: "NOTIFICATION_GREEN_IS_BACK_TITLE",
    description: "NOTIFICATION_GREEN_IS_BACK_DESCRIPTION",
    privileges: ["GREEN_IS_BACK"],
    actions: [],
  },
  34: {
    serviceId: 34,
    type: "NOTIFICATION_WE_AGREE_TITLE",
    description: "NOTIFICATION_WE_AGREE_DESCRIPTION",
    privileges: ["WE_AGREE"],
    actions: [],
  },
  28:
    // Warranty status change
    {
      serviceId: 28,
      type: "NOTIFICATION_WARRANTY_STATUS_CHANGE", //"NOTIFICATION_AFTER_SALES_TITLE",
      description: "NOTIFICATION_WARRANTY_STATUS_CHANGE_DESCRIPTION",
      privileges: [
        "WARRANTY_DIGITAL",
        "WARRANTY_FAST_TRACK",
        "WARRANTY_REPAIR",
        "WARRANTY_STANDARD",
      ],
      actions: [],
      // hide: true,
    },
  29:
    // Digital warranties action required
    {
      serviceId: 29,
      type: "NOTIFICATION_DIGITAL_WARRANTIES_ACTION_REQUIRED", //"NOTIFICATION_AFTER_SALES_TITLE",
      description: "NOTIFICATION_DIGITAL_WARRANTIES_DESCRIPTION",
      privileges: ["WARRANTY_DIGITAL"],
      actions: [],
      // hide: true,
    },
  30: {
    serviceId: 30,
    type: "NOTIFICATION_SERVICE_REQUESTS_TITLE",
    description: "NOTIFICATION_SERVICE_REQUESTS_DESCRIPTION",
    privileges: ["SIMPLE_SR"],
    actions: [],
    // hide: true,
  },
  31: {
    serviceId: 31,
    type: "NOTIFICATION_ORDERS_TO_BE_APPROVED_TITLE",
    description: "NOTIFICATION_ORDERS_TO_BE_APPROVED_DESCRIPTION",
    privileges: ["CAN_APPROVE_ORDERS"],
    actions: [],
  },
  32: {
    serviceId: 32,
    type: "NOTIFICATION_ORDERS_APPROVED_TITLE",
    description: "NOTIFICATION_ORDERS_APPROVED_DESCRIPTION",
    privileges: ["ORDER_CREATION"],
    actions: [],
  },
  35: {
    serviceId: 35,
    type: "NOTIFICATION_ORDER_TRACKING_TITLE",
    description: "NOTIFICATION_ORDER_TRACKING_DESCRIPTION",
    privileges: ["ORDER_CREATION"],
    actions: [],
  },
  // 10: {
  //   serviceId: 10,
  //   type: "A new fuctionality is live",
  //   description: "Everytime a new fuctionality goes live",
  //   actions: [],
  // },
  33: {
    serviceId: 33,
    type: "NOTIFICATION_NEW_DIGITAL_SCREEN_PLAYLIST_TITLE",
    description: "NOTIFICATION_NEW_DIGITAL_SCREEN_PLAYLIST_DESCRIPTION",
    privileges: ["DIGITAL_WINDOW"],
    actions: [],
  },
  17: {
    serviceId: 17,
    type: "NOTIFICATION_SUB_USER_APPROVAL_TITLE",
    description: "NOTIFICATION_SUB_USER_APPROVAL_DESCRIPTION",
    privileges: ["LEONARDO_SUBUSERS_APPROVAL"],
    actions: [],
  },
  15: {
    serviceId: 15,
    type: "NOTIFICATION_DIGITAL_WARRANTY_DAMAGED_GOODS", //"NOTIFICATION_AFTER_SALES_TITLE",
    description: "NOTIFICATION_AFTER_DESCRIPTION",
    privileges: ["WARRANTY_DIGITAL"],
    actions: [],
    // hide: true,
  },
  38: {
    serviceId: 38,
    type: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRING_TITLE",
    description: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRING_DESCRIPTION",
    privileges: ["ADT_CATEGORY"],
    actions: [],
  },
  39: {
    serviceId: 39,
    type: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRED_TITLE",
    description: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRED_DESCRIPTION",
    privileges: ["ADT_CATEGORY"],
    actions: [],
  },
  48: {
    serviceId: 48,
    type: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRING_TITLE",
    description: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRING_DESCRIPTION",
    privileges: ["ADT_CATEGORY"],
    actions: [],
  },
  49: {
    serviceId: 49,
    type: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRED_TITLE",
    description: "NOTIFICATION_ASSET_DOWNLOAD_EXPIRED_DESCRIPTION",
    privileges: ["ADT_CATEGORY"],
    actions: [],
  },
  53: {
    serviceId: 53,
    type: "NOTIFICATION_ASSET_CAMPAIGN_IMAGE_EXPIRED_TITLE",
    description: "NOTIFICATION_ASSET_CAMPAIGN_IMAGE_EXPIRED_DESCRIPTION",
    privileges: ["ADT_CATEGORY"],
    actions: [],
  },
};

export const mapNotificationsPreference = (
  servicesList: any,
  notifPreferences: any
): NotificationPreference[] => {
  const notificationPreferences: NotificationPreference[] = [];

  // service = {id: number, name: string}
  servicesList?.forEach((service: any) => {
    let newNotificationPreference = [];
    //////////////////////////////////////
    // Sviluppo ad hoc per i servizi ADT expiring e expired perchè arrivano sdoppiati nei servizi
    // (il servizio per alert e quello per email sono spearati) ma noi vogliamo considerarli come unico
    // per non mostrare le righe duplicate

    const ADTServices = [48, 49];
    if (ADTServices.includes(service.id)) {
      newNotificationPreference = notifPreferences?.filter(
        (_: any) =>
          _?.serviceId === service?.id ||
          (service.id === 48 ? _?.serviceId === 38 : _?.serviceId === 39)
      );

      /////////////////////////////////////////
      /////////////////////////////////////////
    } else if (service.id !== 38 && service.id !== 39) {
      newNotificationPreference = notifPreferences?.filter(
        (_: any) => _?.serviceId === service?.id
      );
    }

    const preferences = mapNotificationPreference(newNotificationPreference);
    preferences && notificationPreferences.push(preferences);
  });

  return notificationPreferences;
};

export const mapNotificationPreference = (notification: any): NotificationPreference | null => {
  if (notification?.[0]?.serviceId in NOTIFICATION_PREFERENCES_CONFIG) {
    const config = NOTIFICATION_PREFERENCES_CONFIG[notification?.[0]?.serviceId];
    const actions = mapNotificationPreferenceActions(notification);

    return { ...config, actions };
  } else return null;
};

// export const mapNotificationPreferenceADT = (notification: any): NotificationPreference | null => {
//   const ADTServices = [38, 39, 48, 49];
//   if (notification?.[0]?.serviceId in ADTServices) {
//   }
// };

/**
 *  Get the notification preferences actions mapped
 *
 * @param {NotificationPreferencesPayload[]} notification
 * @return {*}  {NotificationPreferenceActions[]}
 */
const mapNotificationPreferenceActions = (notification: any): NotificationPreferenceActions[] => {
  return notification?.map((notif: any) => ({
    serviceId: notif?.serviceId,
    type: mapNotificationTypeIdToType[notif?.typeId ?? 0],
    typeId: notif?.typeId ?? 0,
    active: notif?.active as boolean,
    enabled: notif?.enabled as boolean,
  }));
};

export const mapNotificationActionToUpdatePayload = (
  notifs: NotificationPreferenceActions[]
): NotificationPreferencesUpdatePayload[] => {
  return notifs.map((_) => {
    return {
      serviceId: _?.serviceId as number,
      typeId: _?.typeId,
      active: _?.active as boolean,
    };
  });
};

/**
 * Generic getter of time from date object given a string, by specifying the  date format as reported at:
 * https://date-fns.org/v2.22.1/docs/parse
 *
 * @param {string} date
 * @param {string} dateFormat
 * @param {Intl.DateTimeFormatOptions} options An object that contains one or more properties that specify comparison options.
 * @returns {string | undefined}
 */

export const getTimeFromGenericString = (
  date: string,
  dateFormat: string,
  options: Intl.DateTimeFormatOptions
): string | undefined => {
  return getDateFromGenericString(date, dateFormat)?.toLocaleTimeString([], options);
};
