import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";
import useTranslation from "../../../hooks/useTranslation";

import { RequestStatus } from "../../../interfaces/mainInterfaces";
import { ItemPrice, ProductCategory, Sku } from "../../../interfaces/productInterface";
import { getPagePath } from "../../../routing/routesUtils";
import { SparePartsSku } from "../../../store/aftersales/aftersalesInterface";
import { sendAnalyticsData } from "../../../store/analytics/analyticsService";
import { PreCartProduct } from "../../../store/catalogue/catalogueInterface";
import {
  selectLoadingAvailability,
  selectProductAvailability,
} from "../../../store/catalogue/catalogueSlice";
import { Door } from "../../../store/user/userInterfaces";
import {
  selectIsMultidoor,
  selectIsStarsMultidoor,
  selectIsSubuser,
  selectSelectedDoor,
  setSelectedDoor,
} from "../../../store/user/userSlice";
import { instanceOfSparePartsSku } from "../../../utils/aftersalesUtils";
import CustomText from "../../styled-UI/CustomText";
import IconButton from "../../styled-UI/IconButton";
import InputComponent from "../../styled-UI/InputComponent";
import LinkButton from "../../styled-UI/LinkButton";
import Loader from "../../styled-UI/Loader";
import AvailabilityStatus from "../AvailabilityStatus";
import { RxLocation } from "../Rx/Rx";

import { ReactComponent as Green } from "../../../assets/icons/Green.svg";
import { ReactComponent as Red } from "../../../assets/icons/Red.svg";
import { ReactComponent as BulbIcon } from "../../../assets/icons/bulb.svg";
import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/checkmark-icon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-icon.svg";
import { ReactComponent as PrescriptionIcon } from "../../../assets/icons/prescription-icon.svg";
import { ReactComponent as RxIcon } from "../../../assets/icons/rx-enabled.svg";
import { ReactComponent as RxIconFilled } from "../../../assets/icons/rx-selected.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/star-icon.svg";
import { selectAddToCartLoading } from "../../../store/cart/cartSlice";
import { getPrivilegesRequiredToAddToCart } from "../../../utils/catalogueUtils";
import { getAttributeValues } from "../../../utils/productUtils";
import Tooltip from "../../styled-UI/Tooltip";

const MAX_QUANTITY = 999;

const isQuantityBelowMax = (value: number, increment: number): boolean =>
  value + increment <= MAX_QUANTITY;

const getSizeLabel = (sku: Sku | SparePartsSku, showFamilySize: boolean) => {
  const sizeAttribute =
    sku.productCategory === "accessory"
      ? "ACC_SIZE_FAMILY"
      : sku.productCategory === "goggle" || sku.productCategory === "helmet"
      ? "G_H_SIZE_FAMILY"
      : "FRAME_SIZE_FAMILY";
  const sizeFamily = getAttributeValues(sku?.attributes ?? [], sizeAttribute)?.values;
  const sizeFamilyLabelFrame = showFamilySize && sizeFamily ? ` (${sizeFamily})` : "";
  const sizeFamilyLabelNotFrame = sizeFamily ? ` ${sizeFamily}` : "";

  if (
    sku.productCategory === "goggle" ||
    sku.productCategory === "helmet" ||
    sku.productCategory === "afa"
  ) {
    return `${sizeFamilyLabelNotFrame}`;
  }
  return `${sku?.size?.values?.[0]?.value}${sizeFamilyLabelFrame}`;
};

interface Props {
  sku: Sku | SparePartsSku;
  quantity: number;
  availabilityStatus?: string;
  onClick: (obj: PreCartProduct) => void;
  price?: ItemPrice;
  customerReference?: string;
  disabled?: boolean;
  label?: string;

  // cart only
  orderItemId?: string; // in cart we use this component to update the existing cart, and we need to use orderItemId instead of sku.uniqueId
  isCart?: boolean;
  outOfStock?: boolean; // out of stock items cannot be updated
  isSplitFrames?: boolean; // sizes are displayed differently when in split frames
  isOrderApproval?: boolean; // hide rx
  isPrescriptionUploadError?: boolean;
  isM4C?: boolean;
  hideAvailabilitySize?: boolean;
  isRxItem?: boolean;
  isEmpty?: boolean; // flag to reset values when items have been added to cart
  prescriptionUploadStatus?: RequestStatus; // show red dot on prescription icon
  isAfa?: boolean;
  door?: Door; //for popup multidoor, get door from props
  isLoading?: boolean;
  isStarsNew?: boolean;
  showFamilySizeFrames?: boolean;

  isSpareParts?: boolean;
  "data-element-id"?: string;
  "data-description"?: string;
  dataElementIdMainBox?: string;
  dataElementIdRXPLP?: string;
  dataElementIdRXCart?: string;
}

const AddSize = ({
  sku,
  quantity,
  availabilityStatus,
  onClick,
  price,
  customerReference,
  disabled,
  label,
  orderItemId,
  isCart = false,
  outOfStock = false,
  isSplitFrames = false,
  isOrderApproval = false,
  isM4C = false,
  hideAvailabilitySize = false,
  isRxItem = false,
  isEmpty = false,
  prescriptionUploadStatus,
  isStarsNew,
  showFamilySizeFrames = false,

  door,
  isLoading = false,

  "data-element-id": dataElementId,
  "data-description": dataDescription,
  dataElementIdMainBox,
  dataElementIdRXPLP,
  dataElementIdRXCart,
  isAfa,
  isSpareParts,
}: Props): JSX.Element => {
  const history = useHistory();
  const canRender = useCanRender();
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();

  const isSubuser = useSelector(selectIsSubuser);
  const isMultidoor = useSelector(selectIsMultidoor);
  const selectedDoor = useSelector(selectSelectedDoor);
  const currentDoor = door ? door : selectedDoor; // current door is selectedDoor, but can be overwritten by props
  const isAddToCartLoading = useSelector(selectAddToCartLoading);
  const isStarsMultidoor = useSelector(selectIsStarsMultidoor);

  const id = isCart && orderItemId ? orderItemId : sku?.partNumber; // different ids if we are in cart or catalogue

  const category = sku?.attributes?.find(
    (attribute) => attribute.identifier === "PRODUCT_CATEGORY_FILTER"
  )?.values?.[0].identifier; // product category

  const isStars =
    canRender("STARS", currentDoor) &&
    !isCart &&
    !instanceOfSparePartsSku(sku) &&
    (isStarsMultidoor ? isStarsNew : sku.isStarsNew);

  /////////////// reset states when precart is emptied
  useEffect(() => {
    if (isEmpty) {
      setNewQuantity(0);
      setNewCustomRef("");
    }
  }, [isEmpty]);

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// AVAILABILITY //////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  /////////////// get availability status
  const productAvailabityStatus = useSelector(selectProductAvailability);
  const availabilityLoading = useSelector(selectLoadingAvailability);

  if (id) {
    availabilityStatus =
      availabilityStatus ?? // if it's not passed as props (in cart & spareparts)
      productAvailabityStatus // get availability from redux
        ?.filter((door) => door.doorId === currentDoor?.orgentityName)?.[0] // look for current door
        ?.availabilityStatus.filter((status) => status?.[sku.uniqueID])[0]?.[sku.uniqueID]; // look for current id
  }

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////// QUANTITY ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const [newQuantity, setNewQuantity] = useState<number>(quantity);
  const quantityInput = React.useRef<HTMLInputElement>(null);

  const quantityStep = instanceOfSparePartsSku(sku) && sku.uom === "PAI" ? 2 : 1; // increments at which quantity should be updated

  // keep quantity aligned with redux
  useEffect(() => {
    if (quantity !== newQuantity) setNewQuantity(quantity);
  }, [quantity]);

  // handle quantity changes when using + and - buttons
  const handleUpdateQuantity = (value: number, increment: boolean) => {
    let items = newQuantity ? newQuantity : 0;

    if (increment) {
      if (isQuantityBelowMax(items, value)) items += value;
    } else if (items > 0) items -= value;

    handleOnClick(items);
    setNewQuantity(items);
  };

  // handle quantity changes when inserting number via input
  const handleChangeQuantity = () => {
    if (quantityInput.current?.value) {
      const roundedQuantity =
        Math.ceil(Number(quantityInput.current.value) / quantityStep) * quantityStep;

      const maxedQuantity = isQuantityBelowMax(roundedQuantity, 0) ? roundedQuantity : MAX_QUANTITY;

      handleOnClick(maxedQuantity);
      setNewQuantity(maxedQuantity);
    }
  };

  // actual setting of new quantity with callback onClick
  const handleOnClick = (newQuantity: number) => {
    if (currentDoor?.orgentityId)
      onClick({
        partNumber: id,
        quantity: newQuantity,
        multidoorId: currentDoor?.orgentityId,
        orgentityName: currentDoor?.orgentityName,
        sku: sku,
        price: !instanceOfSparePartsSku(sku) ? price?.opt?.unitPrice : sku?.SPprice,
        availability: availabilityStatus,
        isStars: isStars,
      });
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// CUSTOMER NOTES /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  /////////////// Customer Reference updates
  const [newCustomRef, setNewCustomRef] = useState<string>(customerReference ?? "");
  const textInput = useRef<HTMLInputElement | null>(null);

  // keep customer reference aligned with redux
  useEffect(() => {
    if (customerReference !== undefined && newCustomRef !== customerReference)
      setNewCustomRef(customerReference.replace(/\n/g, "").trim());
  }, [customerReference]);

  const handleChangeCustomerReference = () => {
    //setNewCustomRef(textInput.current?.value.replace(/\n/g, "").trim() ?? "");
    setNewCustomRef(textInput.current?.value ?? "");

    if (textInput.current && id && currentDoor?.orgentityId)
      onClick({
        partNumber: id,
        customerReference: textInput.current?.value.replace(/\n/g, "").trim(),
        multidoorId: currentDoor?.orgentityId,
        orgentityName: currentDoor?.orgentityName,
        sku: sku,
      });
  };

  //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////// RX ///////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const handleRxRedirect = (editPrescription = false) => {
    //For multidoor navigation, is expected to land on the prescription page with door already prefiltered
    dispatch(setSelectedDoor(currentDoor));
    const objToSend: RxLocation = {
      pathname: getPagePath("/rx-prescription"),
      state: {
        selectedProduct: {
          orderItemId: orderItemId as string,
          collection:
            sku?.attributes
              ?.find(
                (attr) =>
                  attr.identifier === "PRODUCT_CATEGORY_CHIPS" ||
                  attr.identifier === "PRODUCT_CATEGORY_FILTER"
              )
              ?.values?.[0]?.identifier?.toLocaleLowerCase() === "eyeglasses"
              ? "OPTICAL"
              : "SUN",
          brand: sku?.brand as string,
          jobType: "RX_COMPLETE_JOB",
          partNumberSku: sku?.partNumber,
          isFromCart: isCart,
        },
      },
    };

    if (editPrescription) {
      objToSend.state = {
        orderItemId,
      };
    }

    history.push(objToSend);
  };

  //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////// RENDER /////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  // whether to hide quantity buttons
  const computeHideInputs = (): boolean | undefined => {
    if (isCart) return false;

    if (availabilityStatus === undefined || availabilityStatus === "NOT_AVAILABLE") return true; // hidden if we don't have availability, or it's not available

    if (!instanceOfSparePartsSku(sku)) {
      //////////// for normal skus
      const privilegesRequired = getPrivilegesRequiredToAddToCart(
        category as ProductCategory | undefined,
        !!isSubuser
      );

      if (!canRender(privilegesRequired, currentDoor)) return true; // hidden if current door doesn't have the privileges required
      if (!price?.opt?.unitPrice) return true; // hidden if price is missing
      return false; // otherwise show
    } else {
      //////////// for spareparts
      const privilegesRequired = "ADD_TO_CART_SPARE_PARTS";

      if (!canRender(privilegesRequired, currentDoor)) return true; // hidden if current door doesn't have the privileges required
      if (!sku.SPprice) return true; // hidden if price is missing
      if (sku.notOrderFlag) return true; // hidden if is flagged as not to be ordered
      return false; // otherwise show
    }
  };

  const hideInputs = computeHideInputs();
  const showMessageNoSkuForDoor = isMultidoor && availabilityStatus === "NOT_AVAILABLE";
  const showRXIcon = (sku?.isRxable || isRxItem) && canRender("RX_ICON", currentDoor);
  const showWarning =
    showRXIcon && !outOfStock && !showMessageNoSkuForDoor && sku?.buyable === "false" && !isCart;

  return (
    <SizeContainer
      className={clsx(isStars && "add-size-stars", isSpareParts && "only-side-padding")}
    >
      <SizeBox className={clsx(isSpareParts && "justify-end")}>
        {/* START SIZE & ICONS */}
        {!isM4C && !hideAvailabilitySize && (
          <AvailabilityContainer>
            {isStars && (
              <IconContainer className="stars-icon">
                <StarIcon />
              </IconContainer>
            )}
            {!isStars && (
              <AvailabilityStatus
                inventoryStatus={availabilityStatus as string}
                hide={isSplitFrames || (showMessageNoSkuForDoor && !isCart)}
                showRedSemaphore={instanceOfSparePartsSku(sku) || isCart}
                id={sku.uniqueID}
                isCart={isCart}
                isAfa={isAfa}
              />
            )}

            <CustomText
              as="span"
              fontSizePx={13}
              marginLeftPx={8}
              marginRightPx={8}
              whiteSpace="nowrap"
            >
              {translateLabel("SIZE")}
            </CustomText>

            <CustomText
              as="span"
              fontSizePx={13}
              font="font-bold"
              marginRightPx={16}
              whiteSpace="nowrap"
            >
              <SizeValue hide={isSplitFrames}>{getSizeLabel(sku, showFamilySizeFrames)}</SizeValue>
            </CustomText>
          </AvailabilityContainer>
        )}
        {/* END SIZE & ICONS */}

        <FlexContainer>
          {showRXIcon && (
            <>
              {isCart && isRxItem ? (
                <IconContainer
                  className="rx-icon"
                  onClick={() => {
                    !outOfStock && !isOrderApproval && handleRxRedirect(true);
                  }}
                  notClickable={outOfStock || isOrderApproval}
                >
                  <RxIconFilled />
                </IconContainer>
              ) : (
                <IconContainer
                  className={clsx(outOfStock ? "icon-out-of-stock" : "rx-icon")}
                  onClick={() => {
                    sendAnalyticsData({
                      id: "Click",
                      Tracking_Type: "link",
                      data_element_id:
                        dataElementIdMainBox || dataElementIdRXPLP || dataElementIdRXCart,
                      data_description: dataDescription,
                    });
                    !outOfStock && !isOrderApproval && handleRxRedirect();
                  }}
                  notClickable={outOfStock || isOrderApproval}
                >
                  <RxIcon />
                </IconContainer>
              )}
            </>
          )}

          {/* TOOLTIP NUANCE FOR SKU NOT BUYABLE */}
          {showWarning && (
            <TooltipNuance>
              <Tooltip
                content={
                  <TooltipContent>
                    <CustomText
                      as="span"
                      fontSizePx={13}
                      lineHeightPx={18}
                      font="font-regular"
                      textAlign="center"
                    >
                      {translateLabel("ITEM_AVAILABLE_WITH_PRESCRIPTION")}
                    </CustomText>
                  </TooltipContent>
                }
              >
                <FavoritesIconContainer>
                  <FirstCircle />
                  <BulbIcon style={{ zIndex: 1 }} />
                </FavoritesIconContainer>
              </Tooltip>
            </TooltipNuance>
          )}

          {/* START QUANTITY INPUT */}
          {isLoading || (availabilityStatus === undefined && availabilityLoading) ? (
            <BoxLoader>
              <Loader sizePx={30} />
            </BoxLoader>
          ) : sku.productCategory === "afa" && !canRender("AFA_ORDER_CREATION") ? (
            <></>
          ) : (
            <>
              {((!showMessageNoSkuForDoor && sku?.buyable !== "false") || isCart) && (
                <AddSizeContainer className={clsx(hideInputs && "hide")}>
                  <IconButton
                    icon={<MinusIcon />}
                    type={isStars ? "white-no-border" : "primary"}
                    size="sm"
                    onClick={() => handleUpdateQuantity(quantityStep, false)}
                    outOfStock={outOfStock}
                    disabled={
                      (isCart ? newQuantity == quantityStep : newQuantity == 0) ||
                      hideInputs ||
                      isRxItem ||
                      isM4C ||
                      disabled ||
                      isAddToCartLoading
                    }
                  />
                  <ItemCount
                    ref={quantityInput}
                    value={newQuantity}
                    type="number"
                    onChange={handleChangeQuantity}
                    min={isCart ? 1 : 0}
                    disabled={
                      outOfStock ||
                      hideInputs ||
                      isRxItem ||
                      isM4C ||
                      disabled ||
                      isAddToCartLoading
                    }
                  />
                  <IconButton
                    icon={<PlusIcon />}
                    type={isStars ? "white-no-border" : "primary"}
                    size="sm"
                    onClick={() => handleUpdateQuantity(quantityStep, true)}
                    outOfStock={outOfStock}
                    disabled={
                      !isQuantityBelowMax(newQuantity, quantityStep) ||
                      hideInputs ||
                      isRxItem ||
                      isM4C ||
                      disabled ||
                      isAddToCartLoading
                    }
                  />
                </AddSizeContainer>
              )}
              {showMessageNoSkuForDoor && !isCart && (
                <div>
                  <CustomText
                    lineHeightPx={18}
                    as="p"
                    fontSizePx={13}
                    font="font-medium"
                    textAlign="right"
                    color={isStars ? "white" : "primary"}
                  >
                    {translateLabel("MULTIDOOR_SKU_NOT_AVAILABLE")}
                  </CustomText>
                </div>
              )}
            </>
          )}
          {/* END QUANTITY INPUT */}
        </FlexContainer>
      </SizeBox>

      {/* OPTIONAL LABEL */}
      {label && (
        <LabelContainer>
          <CustomText as="p" fontSizePx={13} font="font-medium">
            {label}
          </CustomText>
        </LabelContainer>
      )}

      {/* RX PRESCRIPTION */}
      <div>
        {isCart &&
          !isOrderApproval &&
          (sku?.isRxable || isRxItem) &&
          canRender("RX_ICON", currentDoor) &&
          (isRxItem ? (
            <PrescriptionContainer>
              <FlexContainer>
                <CustomText
                  marginTopPx={16}
                  marginBottomPx={2}
                  as="p"
                  fontSizePx={13}
                  font="font-medium"
                >
                  {translateLabel("CART_RX_ADDED")}
                </CustomText>
                <IconContainer className="checkmark">
                  <CheckmarkIcon />
                </IconContainer>
              </FlexContainer>
              <FlexContainer>
                <LinkButton
                  onClick={handleRxRedirect.bind(null, true)}
                  {...(dataElementId ? { "data-element-id": dataElementId } : {})}
                  {...(dataDescription ? { "data-description": dataDescription } : {})}
                >
                  {translateLabel("CART_RX_EDIT_BUTTON")}
                </LinkButton>
                <IconContainer className="prescription" outOfStock={outOfStock}>
                  <PrescriptionIcon />
                  {prescriptionUploadStatus === "ERROR" && <Red className="prescription-dot" />}
                  {prescriptionUploadStatus === "SUCCESS" && <Green className="prescription-dot" />}
                </IconContainer>
              </FlexContainer>
            </PrescriptionContainer>
          ) : (
            <AddPrescriptionContainer>
              <LinkButton
                disabled={outOfStock}
                onClick={() => {
                  handleRxRedirect();
                }}
              >
                {translateLabel("CART_RX_ADD_BUTTON")}
              </LinkButton>
            </AddPrescriptionContainer>
          ))}
      </div>

      {/* START CUSTOMER REFERENCE */}
      {newQuantity > 0 && !isCart && (
        <>
          <CustomerContainer>
            {sku?.upc && !instanceOfSparePartsSku(sku) && (
              <CustomText font="font-regular" fontSizePx={13} as="span" marginTopPx={4}>
                {translateLabel("UPC") + " " + sku.upc}
              </CustomText>
            )}
          </CustomerContainer>

          <InputContainer>
            <InputComponent
              as="textarea"
              type="text"
              placeholder={translateLabel("ADD_NOTE")}
              onChange={handleChangeCustomerReference}
              ref={textInput}
              value={newCustomRef}
              maxLength={250}
            />
          </InputContainer>
        </>
      )}
      {/* END CUSTOMER REFERENCE */}
    </SizeContainer>
  );
};

const TooltipNuance = styled.div`
  @media (min-width: 1440px) {
    margin-left: 1rem;
  }
`;

const TooltipContent = styled.div`
  @media ${(props) => props.theme.queries.md} {
    max-width: 4rem;
  }
  max-width: 8rem;
`;

const FavoritesIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FirstCircle = styled.div`
  background-color: ${(props) => props.theme.palette.green};
  border-radius: 50%;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
`;
const SizeContainer = styled.div`
  margin-bottom: 0.625rem;
  padding: 0.625rem;

  &.add-size-stars {
    background-color: ${(props) => props.theme.palette.primary};

    border-radius: 0.25rem;

    span {
      color: ${(props) => props.theme.palette.white}!important;
    }

    .stars-icon {
      svg {
        fill: ${(props) => props.theme.palette.white}!important;
      }
    }

    .rx-icon {
      svg {
        fill: ${(props) => props.theme.palette.white}!important;
      }
    }
  }

  &.only-side-padding {
    margin-bottom: 1.5rem;
    padding: 0 0.625rem;
  }
`;

const SizeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }
`;

const IconContainer = styled.div<{ notClickable?: boolean; outOfStock?: boolean }>`
  width: 2rem;
  height: 2rem;
  svg {
    fill: ${(props) => props.theme.palette.secondary};
  }

  &.icon-out-of-stock {
    svg {
      fill: ${(props) => props.theme.palette.gray.dark};
    }
  }
  &.checkmark {
    margin-top: 1rem;
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
    svg {
      fill: ${(props) => props.theme.palette.feedback.success};
    }
  }
  &.prescription {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    position: relative;

    > .prescription-dot {
      position: absolute;
      top: -3px;
      right: -3px;
      width: 10px;
      background-color: ${(props) =>
        props.outOfStock ? props.theme.palette.gray.light : props.theme.palette.white};
      border: 2px solid white;
      border-radius: 100%;
    }
  }
  &.rx-icon {
    cursor: ${(props) => (props.notClickable ? "unset" : "pointer")};

    @media ${(props) => props.theme.queries.md} {
      margin-right: 1rem;
    }
  }

  &.stars-icon {
    /* margin-right: 0.5rem; */
    width: 1rem;
    height: 1rem;
  }
`;

const AddSizeContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.625rem;

  &.hide {
    visibility: hidden;
  }
`;

const ItemCount = styled.input`
  margin: 0 0.5625rem;
  width: 2.2rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  text-align: center;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  padding: 0.3125rem;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: 0.8125rem;

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
    appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  [type="number"] {
    -moz-appearance: textfield !important;
  }
`;

const AddPrescriptionContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AvailabilityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
`;

const CustomerContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const InputContainer = styled.div`
  margin-top: 0.5rem;
`;

const SizeValue = styled.div<{ hide: boolean }>`
  display: ${(props) => props.hide && "none"};
`;

const PrescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;

const BoxLoader = styled.div`
  min-width: 115px;
  display: flex;
  justify-content: flex-end;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  max-width: 14rem;
`;

export default AddSize;
